import React, { useState } from "react";
import Modal from "react-modal";
import { post } from "../js/post";
import { isAdmin } from "../js/userData";
import { getToken, getName, getId } from "../js/userData";
import "../css/NavigationBar.css";
import "../css/MessageBox.css";
import "../css/Modal.css";

export default function NavigationBar() {
  const [closeSession, setCloseSession] = useState(false);

  const createResource = async () => {
    const response = await post("CreateResource", {
      token: getToken(),
    });
    window.location = "/resource?resourceId=" + response.resourceId;
  };

  return (
    <div className="navigation-bar-container">
      <div className="navigation-bar-icons" style={{ gap: "5px" }}>
        <img src="images/MICIU+Cofinanciado+AEI.jpg"></img>
        <img src="images/fecyt-1.jpg"></img>
      </div>
      <div className="icons-username-container">
        {getToken() && (
          <span className="user-connected">
            Hi, <span className="bold-text">{getName()}</span>!
          </span>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 15,
          }}
        >
          <img
            className="barIcon"
            src="images/home.svg"
            title="Home"
            onClick={() => {
              window.location = "/";
            }}
          ></img>
          {getToken() && (
            <img
              className="barIcon"
              src="images/new-resource.svg"
              title="New Resource"
              onClick={async () => {
                await createResource();
              }}
            ></img>
          )}
          {getToken() && (
            <img
              className="barIcon"
              src="images/user.svg"
              title="User Profile"
              onClick={() => {
                window.location = "/user?userId=" + getId();
              }}
            ></img>
          )}
          {isAdmin() && (
            <img
              className="barIcon"
              src="images/users.svg"
              title="Users"
              onClick={() => {
                window.location = "/users";
              }}
            ></img>
          )}
          {isAdmin() && (
            <img
              className="barIcon"
              src="images/tag.svg"
              title="Tags"
              onClick={() => {
                window.location = "/tags";
              }}
            ></img>
          )}
          {isAdmin() && (
            <img
              className="barIcon"
              src="images/admin.svg"
              title="Admin Panel"
              onClick={() => {
                window.location = "/admin";
              }}
            ></img>
          )}
          {getToken() && (
            <img
              className="barIcon"
              src="images/log-out.svg"
              title="Log out"
              onClick={() => {
                setCloseSession(true);
                Modal.setAppElement("#root");
              }}
            ></img>
          )}
          {!getToken() && (
            <img
              className="barIcon"
              src="images/log-in.svg"
              title="Log in"
              onClick={() => {
                window.location = "/login";
              }}
            ></img>
          )}
        </div>
      </div>
      <Modal isOpen={closeSession} className="modal">
        <div className="message-box-container modal-box">
          <div className="message-box-question-container">
            <img src="images/warning.svg"></img>
            <span>
              Are you sure you want to{" "}
              <span className="bold-text">log out</span>?
            </span>
          </div>
          <div className="message-box-buttons-container">
            <div
              className="button-18"
              role="button"
              onClick={() => {
                localStorage.clear();
                window.location = "/";
              }}
            >
              {"LOG OUT"}
            </div>
            <div
              className="button-18"
              role="button"
              onClick={() => {
                setCloseSession(false);
              }}
            >
              {"CANCEL"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
